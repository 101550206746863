<template>
  <RevCheckbox
    id="terms-and-conditions-checkbox"
    :model-value
    @update:model-value="
      (value: boolean | string[]) => emit('update:modelValue', value)
    "
  >
    <template #label>
      <FormattedMessage :definition="translations.termsAndCondition">
        <template #termsOfDistanceSelling>
          <RevLink
            target="_blank"
            :to="i18n(translations.termsOfDistanceSellingLink)"
            >{{ i18n(translations.termsOfDistanceSelling) }}
          </RevLink>
        </template>
        <template #termsOfService>
          <RevLink target="_blank" :to="i18n(translations.termsOfServiceLink)"
            >{{ i18n(translations.termsOfService) }}
          </RevLink>
        </template>
        <template #termsOfPricing>
          <RevLink target="_blank" :to="i18n(translations.termsOfPricingLink)"
            >{{ i18n(translations.termsOfPricing) }}
          </RevLink>
        </template>
        <template #contractualSummary>
          <RevLink target="_blank" :to="contractualSummary">
            {{ i18n(translations.contractualSummary) }}
          </RevLink>
        </template>
        <template #privacyPolicy>
          <RevLink target="_blank" :to="i18n(translations.privacyPolicyLink)"
            >{{ i18n(translations.privacyPolicy) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </template>
  </RevCheckbox>
</template>

<script setup lang="ts">
import { type Url } from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevLink } from '@ds/components/Link'

import translations from './TermsAndConditionsCheckbox.translations'

const i18n = useI18n()

defineProps<{
  modelValue: boolean
  contractualSummary?: Url
}>()

const emit = defineEmits(['update:modelValue'])
</script>
