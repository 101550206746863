export default {
  twoPeriodsPrices: {
    id: 'checkout_services_bouygues_phoneline_setup_two_periods_prices_years',
    defaultMessage:
      '{firstPeriodPrice}/month for {duration}\n then {secondPeriodPrice}/month',
  },
  onePeriodPrice: {
    id: 'checkout_services_bouygues_phoneline_setup_one_period_price',
    defaultMessage: '{price}/month',
  },
}
