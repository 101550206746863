export default {
  termsAndCondition: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions',
    defaultMessage:
      'Je certifie l’exactitude des informations ci-dessus et reconnais avoir préalablement lu et accepté les {termsOfDistanceSelling, html}, les {termsOfService, html}, les {termsOfPricing, html}, le {contractualSummary, html} et j’ai pris connaissance du traitement de mes données tel que décrit dans la {privacyPolicy, html} de Bouygues Telecom.',
  },
  termsOfDistanceSelling: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_of_distance_selling',
    defaultMessage: 'Conditions Générales de Vente à distance',
  },
  termsOfDistanceSellingLink: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_of_distance_selling_link',
    defaultMessage:
      'https://www.bouyguestelecom.fr/static/cms/tarifs/Conditions_Generales_De_Vente_A_Distance.pdf',
  },
  termsOfService: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_of_service',
    defaultMessage: 'Conditions Générales de Service',
  },
  termsOfServiceLink: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_of_service_link',
    defaultMessage:
      'https://www.bouyguestelecom.fr/static/cms/tarifs/conditions-generales-de-service.pdf',
  },
  termsOfPricing: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_of_pricing',
    defaultMessage: 'tarifs',
  },
  termsOfPricingLink: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_of_pricing_link',
    defaultMessage:
      'https://www.bouyguestelecom.fr/static/cms/tarifs/guide-des-tarifs-gp-octobre-2023.pdf',
  },
  contractualSummary: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_contractual_summary',
    defaultMessage: 'récapitulatif contractuel',
  },
  privacyPolicy: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_privacy_policy',
    defaultMessage: 'Politique de Confidentialité',
  },
  privacyPolicyLink: {
    id: 'checkout_services_bouygues_phoneline_setup_terms_and_conditions_privacy_policy_link',
    defaultMessage:
      'https://www.bouyguestelecom.fr/static/cms/tarifs/Politique_De_Confidentialite.pdf',
  },
}
