<template>
  <RevForm :initial-values :validate @submit="handleSubmit">
    <template #default="{ values, errors }">
      <div class="space-y-32">
        <div class="space-y-16">
          <h3 class="heading-3">{{ i18n(translations.setUpYourLineLabel) }}</h3>
          <PhonelineCard
            v-model:offer-option="values.offerOption"
            v-model:phone-number="values.phoneNumber"
            v-model:rio-code="values.rioCode"
            :apiErrors
            :errors
            :is-loading
            :price-options="offer?.priceOptions"
          />
        </div>

        <template v-if="!isLoading">
          <div class="space-y-16">
            <h3 class="heading-3">
              {{ i18n(translations.paymentInformationLabel) }}
            </h3>
            <RevCard class="p-24" space-y-8>
              <RevInputText
                id="phoneline-setup-iban"
                v-model="values.iban"
                :description="i18n(translations.IBANDescription)"
                :error="errors.iban || apiErrors.iban"
                :icon="IconBank"
                :label="i18n(translations.IBAN)"
              />
            </RevCard>
          </div>

          <div class="space-y-16">
            <h3 class="heading-3">{{ i18n(translations.moreOptionsLabel) }}</h3>
            <RevCard class="flex flex-col items-end space-y-8 p-24">
              <RevCheckbox
                :id="values.offerOption"
                v-model="values.childrenProtection"
                class="w-full"
                :image="IconLockLocked"
                :image-props="{
                  alt: i18n(translations.parentalControlIconAlternativeText),
                }"
                variant="full"
              >
                <template #label>
                  {{ i18n(translations.parentalControl) }}
                </template>
                <template #description>
                  {{ i18n(translations.parentalControlInformation) }}
                </template>
              </RevCheckbox>
              <RevLink
                target="_blank"
                :to="i18n(translations.parentalControlLink)"
                >{{ i18n(translations.parentalControlLabel) }}</RevLink
              >
            </RevCard>
          </div>

          <TermsAndConditionsCheckbox
            v-model="values.termsAndConditions"
            :contractual-summary="contractualSummary?.url"
          />

          <p v-if="errors.termsAndConditions" class="text-static-danger-hi">
            {{ errors.termsAndConditions }}
          </p>

          <div class="flex justify-end">
            <RevButton
              :disabled="isSubmitting"
              full-width="adaptive"
              :loading="isSubmitting"
              type="submit"
              variant="primary"
            >
              {{ i18n(translations.submit) }}
            </RevButton>
          </div>
        </template>
      </div>

      <template v-if="!isLoading">
        <FormattedMessage
          class="text-static-default-low caption mt-24 block whitespace-pre-line"
          :definition="translations.returnPolicy"
        >
          <template #callPrice>
            <RevLink target="_blank" :to="i18n(translations.callPriceLink)">
              {{ i18n(translations.callPrice) }}
            </RevLink>
          </template>

          <template #withdrawalForm>
            <RevLink
              target="_blank"
              :to="i18n(translations.withdrawalFormLink)"
            >
              {{ i18n(translations.withdrawalForm) }}
            </RevLink>
          </template>
        </FormattedMessage>

        <RevDivider class="my-16" />

        <BouyguesLogout />

        <ReassuranceItems class="mt-32 md:mt-56">
          <BouyguesReassuranceItems v-if="offer" :benefits="offer.benefits" />
        </ReassuranceItems>
      </template>
    </template>
  </RevForm>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from '#imports'
import { computed, ref } from 'vue'

import { type HttpApiError, MarketCountryCode } from '@backmarket/http-api'
import { checkMobilePlanCustomerAccountEligibility } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan'
import type { MobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevDivider } from '@ds/components/Divider'
import {
  FORM_VALID,
  RevForm,
  makeValidate,
  required,
} from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'
import { IconBank } from '@ds/icons/IconBank'
import { IconLockLocked } from '@ds/icons/IconLockLocked'

import BouyguesReassuranceItems from '~/scopes/checkout/components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import { useUser } from '~/scopes/checkout/composables/useUser'
import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { useBouyguesStore } from '~/scopes/checkout/stores/bouyguesStore'
import { getAPIValidationError } from '~/scopes/checkout/utils/getAPIValidationError'
import { getFormattedPhoneNumber } from '~/scopes/checkout/utils/getFormattedPhoneNumber'
import { validateFrenchPhoneNumber } from '~/scopes/checkout/validators/validateFrenchPhoneNumber'
import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'

import TermsAndConditionsCheckbox from '../TermsAndConditionsCheckbox/TermsAndConditionsCheckbox.vue'

import translations from './PhonelineSetupForm.translations'
import type { Values } from './PhonelineSetupForm.types'
import BouyguesLogout from './components/BouyguesLogout/BouyguesLogout.vue'
import PhonelineCard from './components/PhonelineCard/PhonelineCard.vue'

const props = defineProps<{
  offer?: MobilePlanOffer | null
  isLoading: boolean
}>()

const { openErrorToast } = useTheToast()

const user = useUser()
const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const { trackFormSubmit } = useTracking()

const { setPhonelineSetupInformation, phonelineSetupInformation: storedInfo } =
  useBouyguesStore()

const contractualSummary = computed(() =>
  props.offer?.documents.find(
    (document) => document.documentType === 'CONTRACT_SUMMARY',
  ),
)

const isSubmitting = ref(false)
const apiErrors = ref<{
  rioCode?: string
  iban?: string
  phoneNumber?: string
}>({})

const initialValues = {
  offerOption: storedInfo?.offerOption || 'KEEP_PHONE_NUMBER',
  rioCode: storedInfo?.rioCode || '',
  iban: storedInfo?.iban || '',
  phoneNumber:
    storedInfo?.phoneNumber ||
    getFormattedPhoneNumber(
      MarketCountryCode.FR,
      user.phone.dial,
      user.phone.phone,
    ) ||
    '',
  childrenProtection: storedInfo?.childrenProtection || false,
  termsAndConditions: false,
}

const validate = makeValidate<Values>({
  offerOption: required(i18n(translations.inputRequired)),
  iban: required(i18n(translations.inputRequired)),
  phoneNumber: (value, values, ctx) => {
    if (values.offerOption === 'NEW_PHONE_NUMBER') return FORM_VALID

    return validateFrenchPhoneNumber(
      i18n(translations.inputRequired),
      i18n(translations.phoneNumberInputErrorInvalid),
    )(value, values, ctx)
  },
  rioCode: (value, values, ctx) => {
    if (values.offerOption === 'NEW_PHONE_NUMBER') return FORM_VALID

    return required(i18n(translations.inputRequired))(value, values, ctx)
  },
  termsAndConditions: (value) =>
    value === true
      ? FORM_VALID
      : i18n(translations.inputTermsAndConditionRequired),
})

async function handleSubmit(values: Values) {
  isSubmitting.value = true

  try {
    const body: {
      partner: string
      iban: string
      phoneNumber?: string
      rioCode?: string
    } = {
      partner: 'BOUYGUES_TELECOM',
      iban: values.iban,
    }

    const keepPhoneNumber = values.offerOption === 'KEEP_PHONE_NUMBER'

    if (keepPhoneNumber) {
      body.phoneNumber = values.phoneNumber
      body.rioCode = values.rioCode
    }

    const { eligible: isEligible } = await $httpFetch(
      checkMobilePlanCustomerAccountEligibility,
      { body },
    )

    if (!isEligible) {
      router.push({ name: CHECKOUT.SERVICES.BOUYGUES.NOT_ELIGIBLE })

      return
    }

    setPhonelineSetupInformation({
      offerOption: values.offerOption,
      iban: values.iban,
      rioCode: keepPhoneNumber ? values.rioCode : null,
      phoneNumber: keepPhoneNumber ? values.phoneNumber : null,
      childrenProtection: values.childrenProtection,
    })

    trackFormSubmit({
      zone: 'bouygues_phoneline_setup',
      name: 'phoneline_setup',
      page_type: route.name as string,
      offer_option: values.offerOption,
      children_protection: values.childrenProtection,
    })

    router.push({ name: CHECKOUT.PAYMENT })
  } catch (err) {
    const httpApiError = err as HttpApiError

    if (httpApiError.status === 400) {
      apiErrors.value.phoneNumber = getAPIValidationError<Values>(
        httpApiError.errors,
        'phoneNumber',
        '/errors/invalid-format',
        i18n(translations.phoneNumberInputErrorInvalid),
      )
      apiErrors.value.rioCode = getAPIValidationError<Values>(
        httpApiError.errors,
        'rioCode',
        '/errors/invalid-format',
        i18n(translations.RIOInputErrorInvalid),
      )
      const ibanFormatApiError = getAPIValidationError<Values>(
        httpApiError.errors,
        'iban',
        '/errors/invalid-parameter',
        i18n(translations.IBANInputErrorInvalid),
      )
      const ibanBankAccountNotAllowedApiError = getAPIValidationError<Values>(
        httpApiError.errors,
        'iban',
        '/errors/bank-account-type-not-allowed',
        i18n(translations.IBANInputErrorAccountNotAllowed),
      )
      apiErrors.value.iban =
        ibanFormatApiError || ibanBankAccountNotAllowedApiError

      return
    }

    openErrorToast()
  } finally {
    isSubmitting.value = false
  }
}
</script>
