<template>
  <div class="text-static-default-low caption">
    <p>{{ i18n(translations.bouyguesAccount) }}</p>
    <RevLink :to="logoutUrl.href">{{ i18n(translations.logoutLink) }}</RevLink>
  </div>
</template>

<script setup lang="ts">
import { useLinkResolver, useRuntimeConfig } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevLink } from '@ds/components/Link'

import { CHECKOUT } from '~/scopes/checkout/routes-names'

import translations from './BouyguesLogout.translations'

const i18n = useI18n()
const runtimeConfig = useRuntimeConfig()
const { getLink } = useLinkResolver()
const {
  market: { countryCode },
} = useMarketplace()

const redirectUri = getLink({
  link: { name: CHECKOUT.SERVICES.BOUYGUES.SIGN_UP },
  countryCode,
})?.href

const logoutUrl = new URL(
  runtimeConfig.public.BOUYGUES_TELECOM_OAUTH_LOGOUT_URL,
)

if (redirectUri) logoutUrl.searchParams.append('service', redirectUri)
</script>
