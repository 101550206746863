<template>
  <span class="whitespace-pre-line">
    <template v-if="prices.length === 1">
      {{
        i18n(translations.onePeriodPrice, {
          price: i18n.price(prices[0].price),
        })
      }}
    </template>

    <template v-if="prices.length === 2 && prices[0].monthsDuration">
      {{
        i18n(translations.twoPeriodsPrices, {
          firstPeriodPrice: i18n.price(prices[0].price),
          secondPeriodPrice: i18n.price(prices[1].price),
          duration: getFormattedDuration(prices[0].monthsDuration),
        })
      }}
    </template>
  </span>
</template>

<script setup lang="ts">
import type { PriceOption } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan/types/mobile-plan-offers'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useFormatMonthsYearsDuration } from '~/composables/useFormatMonthsYearsDuration'

import translations from './PriceOption.translations'

defineProps<{ prices: PriceOption['prices'] }>()

const i18n = useI18n()
const { getFormattedDuration } = useFormatMonthsYearsDuration()
</script>
