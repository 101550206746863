import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  bouyguesAccount: {
    id: 'checkout_services_bouygues_phoneline_setup_bouygues_account',
    defaultMessage: 'You’re logged in to your Bouygues Telecom account.',
  },
  logoutLink: {
    id: 'checkout_services_bouygues_phoneline_setup_logout_link',
    defaultMessage: 'Log in to another account',
  },
}

export default translations
