export default {
  // Set up your line section
  setUpYourLineLabel: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_label',
    defaultMessage: 'Set up your line',
  },
  phoneNumberInputErrorInvalid: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_phone_number_input_error_invalid',
    defaultMessage: 'Enter a valid french phone number',
  },
  RIOInputErrorInvalid: {
    id: 'checkout_services_bouygues_phoneline_setup_payment_input_RIO_input_error_invalid',
    defaultMessage:
      'Enter the correct RIO number that matches your phone number or call below to get yours.',
  },

  // Payment information section
  paymentInformationLabel: {
    id: 'checkout_services_bouygues_phoneline_setup_payment_information_label',
    defaultMessage: 'Payment information',
  },
  IBAN: {
    id: 'checkout_services_bouygues_phoneline_setup_payment_IBAN',
    defaultMessage: 'IBAN',
  },
  IBANDescription: {
    id: 'checkout_services_bouygues_phoneline_setup_payment_input_IBAN_description',
    defaultMessage:
      'This will be used for Bouygues Telecom monthly phone plan payments.',
  },
  IBANInputErrorInvalid: {
    id: 'checkout_services_bouygues_phoneline_setup_payment_input_IBAN_input_error_invalid',
    defaultMessage: 'Enter the IBAN in the right format.',
  },
  IBANInputErrorAccountNotAllowed: {
    id: 'checkout_services_bouygues_phoneline_setup_payment_input_IBAN_input_error_account_not_allowed',
    defaultMessage: 'Enter the IBAN in the right format.',
  },

  // More options section
  moreOptionsLabel: {
    id: 'checkout_services_bouygues_phoneline_setup_more_options_label',
    defaultMessage: 'More options',
  },
  parentalControl: {
    id: 'checkout_services_bouygues_phoneline_setup_parental_control',
    defaultMessage: 'Free parental control',
  },
  parentalControlInformation: {
    id: 'checkout_services_bouygues_phoneline_setup_parental_control_information',
    defaultMessage: 'Add limits to your child’s line.',
  },
  parentalControlIconAlternativeText: {
    id: 'checkout_services_bouygues_phoneline_setup_parental_control_icon_alternative_text',
    defaultMessage: 'Lock icon',
  },
  parentalControlLabel: {
    id: 'checkout_services_bouygues_phoneline_setup_parental_control_link',
    defaultMessage: 'Learn more',
  },
  parentalControlLink: {
    id: 'checkout_services_bouygues_phoneline_setup_parental_control_link_value',
    defaultMessage:
      'https://www.bouyguestelecom.fr/choisir-bouygues-telecom/protection-des-mineurs',
  },

  // Terms and condition
  inputTermsAndConditionRequired: {
    id: 'checkout_services_bouygues_phoneline_setup_input_terms_and_condition_required',
    defaultMessage: 'You must agree to the terms and conditions to continue.',
  },

  // Others
  inputRequired: {
    id: 'checkout_services_bouygues_phoneline_setup_input_required',
    defaultMessage: 'This field is required',
  },
  submit: {
    id: 'checkout_services_bouygues_phoneline_setup_submit',
    defaultMessage: 'Agree and continue',
  },
  returnPolicy: {
    id: 'checkout_services_bouygues_phoneline_setup_return_policy',
    defaultMessage: `Vous disposez d’un délai de rétractation de 14 jours, que vous pouvez exercer en nous contactant au 1064 (prix de l’appel en fonction de votre opérateur dans {callPrice, html}), ou via le Formulaire de rétractation {withdrawalForm, html}.\n
    Si vous avez déjà activé vos services, nous vous facturerons vos consommations et le prorata de vos abonnements et options jusqu’à votre date de rétractation.`,
  },
  callPrice: {
    id: 'checkout_services_bouygues_phoneline_setup_call_price',
    defaultMessage: 'les tarifs',
  },
  callPriceLink: {
    id: 'checkout_services_bouygues_phoneline_setup_call_price_link',
    defaultMessage:
      'https://www.bouyguestelecom.fr/static/cms/tarifs/guide-des-tarifs-gp-octobre-2023.pdf',
  },
  withdrawalForm: {
    id: 'checkout_services_bouygues_phoneline_setup_withdrawal_form',
    defaultMessage: 'Formulaire de rétractation',
  },
  withdrawalFormLink: {
    id: 'checkout_services_bouygues_phoneline_setup_withdrawal_form_link',
    defaultMessage:
      'https://www.bouyguestelecom.fr/static/cms/tarifs/Formulaire_De_Retractation.pdf',
  },
}
