import { getMobilePlanOffer } from '@backmarket/http-api/src/api-specs-b2c-services/mobile-plan'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export function useBouyguesOffer(offerId?: string) {
  const {
    data: offer,
    pending,
    error,
    refresh,
  } = useHttpFetch(getMobilePlanOffer, {
    pathParams: { offerId },
  })

  return { offer, pending, error, refresh }
}
