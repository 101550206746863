export default {
  keepPhoneNumberLabel: {
    id: 'checkout_services_bouygues_phoneline_setup_keep_phone_number_label',
    defaultMessage: 'Keep current phone number',
  },
  newPhoneNumberLabel: {
    id: 'checkout_services_bouygues_phoneline_setup_new_phone_number_label',
    defaultMessage: 'Get a new phone number',
  },
  simCardShippingInfo: {
    id: 'checkout_services_bouygues_phoneline_setup_sim_card_shipping_info',
    defaultMessage:
      'Bouygues Telecom will deliver your new SIM card within 6 business days after confirmation.',
  },
  phoneNumberTitle: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_phone_number_title',
    defaultMessage: "What's your current number?",
  },
  phoneNumberSubtitle: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_phone_number_subtitle',
    defaultMessage:
      'Bouygues Telecom handles everything with your current carrier to make the contract switch.',
  },
  phoneNumberInputLabel: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_phone_number_input_label',
    defaultMessage: 'Phone number',
  },
  phoneNumberInputClear: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_phone_number_input_clear',
    defaultMessage: 'clear',
  },
  RIO: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_RIO',
    defaultMessage: 'RIO',
  },
  RIODescription: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_RIO_description',
    defaultMessage:
      'Your unique relevé d’identité opérateur (RIO) number is used by Bouygues Telecom to transfer your phone number as you change contracts.',
  },
  RIOInfoBlockTitle: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_RIO_info_block_title',
    defaultMessage: 'Get your RIO number',
  },
  RIOInfoBlockContent: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_RIO_info_block_content',
    defaultMessage:
      'Call the number below, and an automated service will instantly text you your RIO number for free.',
  },
  RIOInfoBlockLink: {
    id: 'checkout_services_bouygues_phoneline_setup_set_up_your_line_RIO_info_block_link',
    defaultMessage: 'Call 3179 for RIO number',
  },
}
